<template>
  <layout>
    <template slot="content">
      <div>
        <div class="page-header mb-3 mb-md-2">
          <h3 class="page-title"> Agenda </h3>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Eventos</a></li>
              <li class="breadcrumb-item active" aria-current="page">Todos los eventos</li>
            </ol>
          </nav>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="row align-items-center mb-4">
                  <div class="col-3" v-if="displayFilters">
                    <div class="form-group">
                      <label for="date_filter">Fecha de Vencimiento</label>
                      <select v-model="filters.date_filter" name="date_filter" id="date_filter" class="form-control">
                        <option :value="0" disabled>Seleccionar</option>
                        <option :value="1">Hoy</option>
                        <option :value="2">Mañana</option>
                        <option :value="3">Ésta Semana</option>
                        <option :value="4">Próxima Semana</option>
                        <option :value="5">Personalizado</option>
                      </select>
                    </div>
                  </div>
                  <!-- filter date by calendar -->
                  <div class="col-3" v-if="filters.date_filter === 5" >
                    <div class="form-group">
                      <label for="date">Fecha de Compromiso</label>
                      <datetime v-model="filters.date_custom" :config="dateConfig" id="date" class="form-control"></datetime>
                    </div>
                  </div>
                  <div class="ml-auto col-auto">
                    <div class="dropdown">
                      <button
                        class="btn btn-gradient-info btn-sm dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="mdi mdi-plus"></i> Nuevo
                      </button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a @click.prevent="TOGGLE_CREATE_AGENDA_APPOINTMENT_MODAL(true)" class="dropdown-item" href="#">Agregar Cita</a>
                        <a @click.prevent="TOGGLE_CREATE_AGENDA_TASK_MODAL(true)" class="dropdown-item" href="#">Agregar Tarea</a>
                        <a @click.prevent="TOGGLE_CREATE_AGENDA_AUDIENCE_MODAL(true)" class="dropdown-item" href="#">Agregar Audiencia</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row align-items-center mb-4 tms-event-type">
                  <div class="col-12">
                    <div class="btn-group" role="group" aria-label="Basic example">
                      <button
                        type="button"
                        :class="filters.type === 4 ? 'btn-gradient-info' : 'btn-secondary'"
                        @click.prevent="filters.type = 4"
                        class="btn btn-sm"
                      >
                        <i class="mdi mdi-expand-all"></i> Todo
                      </button>
                      <button
                        type="button"
                        :class="filters.type === 3 ? 'btn-gradient-info' : 'btn-secondary'"
                        @click.prevent="filters.type = 3"
                        class="btn btn-sm tms-event-type__audience"
                      >
                        <i class="mdi mdi-account-group"></i> Audiencias
                      </button>
                      <button
                        type="button"
                        :class="filters.type === 1 ? 'btn-gradient-info' : 'btn-secondary'"
                        @click.prevent="filters.type = 1"
                        class="btn btn-sm tms-event-type__appointment"
                      >
                        <i class="mdi mdi-update"></i> Citas
                      </button>
                      <button
                        type="button"
                        :class="filters.type === 2 ? 'btn-gradient-info' : 'btn-secondary'"
                        @click.prevent="filters.type = 2"
                        class="btn btn-sm tms-event-type__task"
                      >
                        <i class="mdi mdi-checkbox-marked-outline"></i> Tareas
                      </button>
                    </div>
                  </div>
                </div>
                <datatable :api="apiEndPoint" :fields="fields" :otherParams="filters"></datatable>
              </div>
            </div>
          </div>
        </div>
      </div>
      <create-agenda-appointment></create-agenda-appointment>
      <edit-agenda-appointment v-if="selected" :payload="selected"></edit-agenda-appointment>
      <create-agenda-task></create-agenda-task>
      <edit-agenda-task v-if="selected" :payload="selected"></edit-agenda-task>
      <create-agenda-audience></create-agenda-audience>
      <edit-agenda-audience v-if="selected" :payload="selected"></edit-agenda-audience>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/BaseLayout'
import Datatable from '@/components/TableLayout'
import CreateAgendaAppointment from '@/components/CreateAgendaAppointment'
import EditAgendaAppointment from '@/components/EditAgendaAppointment'
import CreateAgendaTask from '@/components/CreateAgendaTask'
import EditAgendaTask from '@/components/EditAgendaTask'
import CreateAgendaAudience from '@/components/CreateAgendaAudience'
import EditAgendaAudience from '@/components/EditAgendaAudience'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import { mapMutations } from 'vuex'

export default {
  name: 'Agenda',
  props: {
    client_request: {
      default: '',
      required: false
    }
  },
  data () {
    return {
      fields: [
        {
          name: 'agenda_event_type',
          title: 'Tipo',
          formatter: (value) => {
            let status = ''
            if (value === 1) {
              status = '<span class="text-info font-weight-bold">Cita</span>'
            }
            if (value === 2) {
              status = '<span class="text-info font-weight-bold">Tarea</span>'
            }
            if (value === 3) {
              status = '<span class="text-info font-weight-bold">Audiencia</span>'
            }
            return status
          }
        },
        {
          name: 'record',
          title: 'Expediente'
        },
        {
          name: 'agenda_client',
          title: 'Cliente',
          visible: false
        },
        {
          name: 'status',
          title: 'Estado',
          formatter: (value) => {
            let status = ''
            if (value === 1) {
              status = '<span class="badge badge-info">Pendiente</span>'
            }
            if (value === 2) {
              status = '<span class="badge badge-info">Completada</span>'
            }
            if (value === 3) {
              status = '<span class="badge badge-info">Re-agendada</span>'
            }
            if (value === 4) {
              status = '<span class="badge badge-info">Cancelada</span>'
            }
            return status
          },
          dataClass: 'text-center',
          titleClass: 'text-center'
        },
        {
          name: 'agenda_action',
          title: 'Action'
        }
      ],
      selected: {},
      apiEndPoint: 'api/agenda',
      displayFilters: true,
      filters: {
        type: 4,
        date_filter: 0,
        date_custom: '',
        client: ''
      },
      dateConfig: {
        altInput: true,
        enableTime: false,
        altFormat: 'F j, Y',
        dateFormat: 'Y-m-d',
        locale: Spanish // locale for this instance only,
      }
    }
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      handler (value) {
        if (value.type) {
          this.$events.fire('refresh-table')
        }
        if (value.date_filter) {
          this.$events.fire('refresh-table')
        }
        if (value.date_custom) {
          this.$events.fire('refresh-table')
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      TOGGLE_CREATE_AGENDA_APPOINTMENT_MODAL: 'Agenda/TOGGLE_CREATE_AGENDA_APPOINTMENT_MODAL',
      TOGGLE_EDIT_AGENDA_APPOINTMENT_MODAL: 'Agenda/TOGGLE_EDIT_AGENDA_APPOINTMENT_MODAL',
      TOGGLE_CREATE_AGENDA_TASK_MODAL: 'Agenda/TOGGLE_CREATE_AGENDA_TASK_MODAL',
      TOGGLE_EDIT_AGENDA_TASK_MODAL: 'Agenda/TOGGLE_EDIT_AGENDA_TASK_MODAL',
      TOGGLE_CREATE_AGENDA_AUDIENCE_MODAL: 'Agenda/TOGGLE_CREATE_AGENDA_AUDIENCE_MODAL',
      TOGGLE_EDIT_AGENDA_AUDIENCE_MODAL: 'Agenda/TOGGLE_EDIT_AGENDA_AUDIENCE_MODAL'
    }),
    resetFilter () {
      this.displayFilters = 0
      this.filters = {
        type: 4,
        date_filter: 0,
        date_custom: ''
      }
      this.$nextTick(() => this.$events.fire('refresh-table'))
    }
  },
  mounted () {
    this.$events.$on('table-item-edit', (data, label) => {
      this.selected = data
      if (this.selected.type === 1) {
        this.TOGGLE_EDIT_AGENDA_APPOINTMENT_MODAL(true)
      }
      if (this.selected.type === 2) {
        this.TOGGLE_EDIT_AGENDA_TASK_MODAL(true)
      }
      if (this.selected.type === 3) {
        this.TOGGLE_EDIT_AGENDA_AUDIENCE_MODAL(true)
      }
    })
  },
  created () {
    if (this.client_request) {
      this.filters.client = this.client_request
    }
  },
  beforeDestroy () {
    this.$events.off('table-item-edit')
  },
  components: {
    Layout,
    Datatable,
    EditAgendaAppointment,
    CreateAgendaAppointment,
    CreateAgendaTask,
    EditAgendaTask,
    CreateAgendaAudience,
    EditAgendaAudience
  }
}
</script>
